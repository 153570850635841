import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setTheme } from "./lookup";

function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    //uploadsuccess
    if (location.pathname.indexOf("/collaborations/") < 0 && 
        location.pathname.indexOf("/posecollaboration") < 0 && 
        location.pathname.indexOf("/collaborationdetail/") < 0 && 
        location.pathname.indexOf("/uploadsuccess") < 0)
        setTheme("");
    //console.log('Page changed to:', location.pathname);
    // Here you can add any effect that should run on page change.
  }, [location]);

  //return <div>Tracking page changes!</div>;
}

export default PageTracker; 