import React, { Component } from 'react';
import { GetAvatar, GetFrameClass, GetUserRanking } from '../../helpers/global';
import { getUser } from '../../helpers/lookup';
import { UserLevels } from './UserLevels';

export class ProfileSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            level: "",
            avatar: "/img/noAvatar1.png",
            score: 0,
            loading: true
        };
    }

    componentDidMount() {
        let user = getUser();
        this.setState({ username: user.FirstName, level: user.Level, avatar: user.Avatar, score: user.Score, loading: false });
    }

    RenderProfile(username, level, avatar, score) {
        return (
            <div className='container bg-white py-2' style={{ marginBottom: "1em" }}>
                <div className='row' >
                    <div className="col-3 px-2 text-center">
                        <a href='/fullprofile' className={'avatar-container mx-2 small ' + GetFrameClass(score)} id="home-profile">
                            <img id='myAvatar' src={GetAvatar(avatar)} alt={username} />
                        </a>
                    </div>
                    <div className="col-9 text-center pt-2">
                        <div className="welcomeName fw-bold ">Welcome back, {username}</div>
                        <div className='small'>Level {this.GetCurrentLevel(score)}</div>
                    </div>
                </div>
                <UserLevels Score={score} hidePoints={false} />
            </div>
        );
    }

    GetCurrentLevel(Score) {
        const ScoreRank = GetUserRanking(Score);
        switch (ScoreRank) {
            case 2:
                return "2. Aspirant Adventurer";
            case 3:
                return "3. Daring Discoverer";
            case 4:
                return "4. Solid Sage";
            case 5:
                return "5. Knowledge Knight";
            case 6:
                return "6. Mentoring Master";
            case 7:
                return "7. Worthy Wizard";
            default:
                return "1. Erudite Explorer";
        }
    }

    render() {

        let content = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.RenderProfile(this.state.username, this.state.level, this.state.avatar, this.state.score);
        return (
            <>{content}</>
        )
    }
}
