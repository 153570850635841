import React, { Component } from 'react';

import { getAuthToken } from '../../helpers/authentication';
import { CheckGroupCount } from '../../helpers/login-helper';
import { loader } from '../../helpers/loader';
import { APIPath } from '../../helpers/APIHelper';
import { CanSavePoints, getCurrentWalkthroughStep, getDeviceId, getUserId, getWalkthroughDetails, setCurrentWalkthroughStep, setStepsWithPoints, setWalkthroughDetails, setWalkthroughStatus } from '../../helpers/lookup';
import { RenderSwirl } from '../../helpers/SwirlHelper';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import swal from 'sweetalert';
import { UpdateWalkthroughPoints } from '../../helpers/global';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './stepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepSevenJob from './StepSevenJob';
import StepSevenProfessional from './StepSevenProfessional';
import StepSevenExperiential from './StepSevenExperiential';
import StepSevenPersonal from './StepSevenPersonal';
import StepEight from './StepEight';
import LastStepScreen1 from './LastStepScreen1';
import LastStepScreen2 from './LastStepScreen2';
import LastStepScreen3 from './LastStepScreen3';


export default class Walkthrough extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CurrentStep: getCurrentWalkthroughStep(),
            loading: true
        };
        this.changeStep = this.changeStep.bind(this);
    }

    componentDidMount() {
        if (getWalkthroughDetails())
            this.setState({ loading: false });
        else
            this.loadData();
    }

    async changeStep(Type, SavedStep) {
        let CurrentStep = Walkthrough.HandleStep(Type, this.state.CurrentStep);
        if (Type.toLowerCase() === "next") {
            if (SavedStep) {
                if (CanSavePoints(SavedStep)) {
                    await this.SaveWalkthroughPoint(SavedStep);
                } else if (SavedStep == "9c")
                    CheckGroupCount();
                else {
                    ThrobbleHelper.toggleThrobble(false);
                    this.setState({ CurrentStep });
                    setCurrentWalkthroughStep(CurrentStep);
                }
            } else {
                ThrobbleHelper.toggleThrobble(false);
                this.setState({ CurrentStep });
                setCurrentWalkthroughStep(CurrentStep);
            }
        } else {
            ThrobbleHelper.toggleThrobble(false);
            this.setState({ CurrentStep });
            setCurrentWalkthroughStep(CurrentStep);
        }
    }

    static HandleStep(type, step) {
        switch (step) {
            case "7job":
                if (type === "prev")
                    return "6";
                else
                    return "7prof";
            case "7prof":
                if (type === "prev")
                    return "7job";
                else
                    return "7exp";
            case "7exp":
                if (type === "prev")
                    return "7prof";
                else
                    return "7pers";
            case "7pers":
                if (type === "prev")
                    return "7exp";
                else
                    return "8";
            case "8":
                if (type === "prev")
                    return "7pers";
                else
                    return "9a";
            case "9a":
                if (type === "prev")
                    return "7pers";
                else return "9b";
            case "8b":
                if (type === "prev")
                    return "9a";
                else return "9c";
            case "6":
                if (type === "prev")
                    return "5";
                else
                    return "7job";
            default:
                step = parseInt(step);
                if (type === "prev")
                    return (step - 1).toString();
                else
                    return (step + 1).toString();
        }
    }

    renderWalkthrough(step) {
        const header = <>NOW.CULI&reg;</>;
        switch (step) {
            case "1":
                return (<StepOne Header={header} onStepChange={this.changeStep} />);
            case "2":
                return (<StepTwo Header={header} onStepChange={this.changeStep} />);
            case "3":
                return (<StepThree Header={header} onStepChange={this.changeStep} />);
            case "4":
                return (<StepFour Header={header} onStepChange={this.changeStep} />);
            case "5":
                return (<StepFive Header={header} onStepChange={this.changeStep} />);
            case "6":
                return (<StepSix Header={header} onStepChange={this.changeStep} />);
            case "7job":
                return (<StepSevenJob Header={header} onStepChange={this.changeStep} />)
            case "7prof":
                return (<StepSevenProfessional Header={header} onStepChange={this.changeStep} />)
            case "7exp":
                return (<StepSevenExperiential Header={header} onStepChange={this.changeStep} />)
            case "7pers":
                return (<StepSevenPersonal Header={header} onStepChange={this.changeStep} />);
            case "8":
                return (<StepEight Header={header} onStepChange={this.changeStep} />);
            case "9a":
                return (<LastStepScreen1 Header={header} onStepChange={this.changeStep} />)
            case "9b":
                return (<LastStepScreen2 Header={header} onStepChange={this.changeStep} />)
            default:
                return (<LastStepScreen3 Header={header} onStepChange={this.changeStep} />);
        }
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthrough(this.state.CurrentStep);
        return (
            <div className='walkthrough-container'>
                {content}
                <ThrobbleHelper />
            </div>);
    }

    async loadData() {
        const bearer = "Bearer " + getAuthToken();
        const UserID = getUserId();
        const DeviceId = getDeviceId();
        try {
            const response = await fetch(APIPath() + "/global/walkthrough/" + UserID + "/" + DeviceId, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.UserId) {
                    setWalkthroughDetails(data);
                }

                this.setState({ loading: false });
                //if (data.WalkThroughStatus == 100)

            }
        } catch (e) {
            console.error("Walkthrough ::", e);
        }
    }

    async setWalkthroughComplete() {
        console.log("Walkthrough Complete");
        const bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/walkthrough/walkthroughcomplete", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {

            }
        } catch (e) {
            console.error("Walkthrough ::", e);
        }
    }

    async SaveWalkthroughPoint(step) {
        try {
            const bearer = "Bearer " + getAuthToken();
            const UserID = getUserId();
            const response = await fetch(APIPath() + "/global/walkthrough/AddPoints/" + UserID, {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                UpdateWalkthroughPoints(step);
                ThrobbleHelper.toggleThrobble(false);
                setWalkthroughStatus();
                RenderSwirl("WALKTHROUGH", "Well done - that earned you 50 points", "success", () => {
                    let CurrentStep = Walkthrough.HandleStep("next", step);
                    if (step === "9c")
                    {
                        ThrobbleHelper.toggleThrobble(true, "Launching App");
                        this.setWalkthroughComplete();
                        setTimeout(() => {
                            CheckGroupCount();
                            
                        }, 1500);
                    }
                    else
                        this.setState({ CurrentStep }, () => setCurrentWalkthroughStep(this.state.CurrentStep))
                });
            } else {
                console.log(response.status, "::", response.statusText);
                ThrobbleHelper.toggleThrobble(false);
                swal("WALKTHROUGH", "There was an error updating your points. Please try again!");
            }
        } catch (e) {
            console.error("Walkthrough Saving Points ::", e);
            ThrobbleHelper.toggleThrobble(false);
            swal("WALKTHROUGH", "There was an error updating your points. Please try again!");
        }
    }

    
}
