import React, { Component } from 'react'
import swal from 'sweetalert';
import { CheckProfile } from '../../helpers/login-helper';
import { getAppVersion, getAppVersionFromLocalStorage, setAppVersion } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export default class InitialScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            welcomeMsg: "Enter your registered KMI program email address and press Login to access the App",
            Email: "",
            termsChecked: false,
            hideButton: false,
            loading: true
        }

        this.renderInitialScreen = this.renderInitialScreen.bind(this);
        this.GotoLogin = this.GotoLogin.bind(this);
    }

    componentDidMount() {
        if (getAppVersionFromLocalStorage() === null) {
            console.log("App Version is not in local storage.");
            setAppVersion(getAppVersion());
        };

        this.setState({ AppVersion: getAppVersion(), loading: false });
    }

    renderInitialScreen() {
        return (
            <div className="pnlWelcome px-3">
                <div className="py-4 mb-2">
                    <h6 className='fw-bold'>Welcome to NOW.CULI&reg;</h6>
                </div>
                <div>
                    <div className='welcome-note'>
                        <div className="login-block">
                            <p id="iMsg" >{this.state.welcomeMsg}</p>
                            <input type="email" id="iEmailText" className='form-control' onChange={e => this.setState({ Email: e.target.value })} />

                            <div className="row my-4">
                                {/* <div className="col-10 text-start">
                                    <a href='https://chq-admin.azurewebsites.net/kmg-legal-notice' target={'_blank'} rel="noreferrer"><span className="action">Accept Terms &amp; Conditions</span></a>
                                </div>
                                <div className="col-2 text-end">
                                    <input type={"checkbox"} id="termsAccept" onChange={e => this.setState({ termsChecked: e.target.checked })} style={{ position: "relative" }} />
                                </div> */}
                            </div>
                            <div className="w-100 mb-3 text-end">
                                <button id="gotoLogin" disabled={this.state.Email === ""} hidden={this.state.hideButton} onClick={this.GotoLogin}> Login <i className='fas fa-chevron-right'></i></button>
                            </div>

                            <p className='mb-2'>Please note that this app is available only to registered participants.</p>
                            <p>Version: {this.state.AppVersion}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let content = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderInitialScreen();
        return (
            <div>
                {content}
                <ThrobbleHelper />
            </div>
        )
    }

    async GotoLogin(evt) {
        evt.stopPropagation();
        this.setState({ hideButton: true });
        ThrobbleHelper.toggleThrobble(true, "Getting your profile info...");
        let hasError = await CheckProfile(this.state.Email);
        if (hasError) {
            ThrobbleHelper.toggleThrobble(false);
            swal("Profile", "Unfortunately your email address is not on the system. Please try again or contact KMI", "error");
            this.setState({ hideButton: false });
        }
    }
}
