/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { loader } from '../../helpers/loader';
import { getUserId, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';
import Select from 'react-select';
import { AddItemToDropdown } from '../../helpers/global';
import { fieldRequired } from '../../helpers/validation';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export default class StepFive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            IndustryInstitution: 0,
            Structures: [],
            institutionLvl1: 0,
            Lvl1List: [],
            institutionLvl2: 0,
            Lvl2List: [],
            institutionLvl3: 0,
            Lvl3List: [],
            Lvl4List: [],
            loading: true,
            ddLvl1HasItem: false,
            ddLvl2HasItem: false,
            ddLvl3HasItem: false,
            changesMade: false
        }

        this.onLvlChange = this.onLvlChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    renderWalkthroughStep() {
        return (<>
            <Modal className='walkthrough-container' isOpen={true} fade fullscreen backdrop="static">
                <ModalHeader>
                    {this.props.Header}
                </ModalHeader>
                <ModalBody>
                    <h3 className='fs-2 fw-bolder'>INDUSTRY INSTITUTIONS</h3>
                    <p>From the dropdown list provided please select the main industry-specific institutions, learned societies or
                        learning accreditation body that you are a member of. If you are a member of several associated bodies (eg. SAICE and SARF)
                        you can add the additional bodies after you have registered.</p>

                    <div className="mb-3">
                        <label>Level 1
                            <span className="ErrorText ms-2" id="errLvl"></span>
                        </label>
                        <Select
                            options={this.state.Lvl1List}
                            value={this.state.Lvl1List.find(c => c.value == this.state.institutionLvl1)}
                            isDisabled={this.state.Lvl1List.length < 2}
                            onChange={e => this.onLvlChange(1, e)}
                        />
                    </div>
                    <div className="mb-3">
                        <label>Level 2</label>
                        <Select
                            options={this.state.Lvl2List}
                            value={this.state.Lvl2List.find(c => c.value == this.state.institutionLvl2)}
                            isDisabled={this.state.Lvl2List.length < 2}
                            onChange={e => this.onLvlChange(2, e)}
                        />
                    </div>
                    <div className="mb-3">
                        <label>Level 3</label>
                        <Select
                            options={this.state.Lvl3List}
                            value={this.state.Lvl3List.find(c => c.value == this.state.institutionLvl3)}
                            isDisabled={this.state.Lvl3List.length < 2}
                            onChange={e => this.onLvlChange(3, e)}
                        />
                    </div>
                    <div className="mb-3">
                        <label>Level 4</label>
                        <Select
                            options={this.state.Lvl4List}
                            value={this.state.Lvl4List.find(c => c.value == this.state.IndustryInstitution)}
                            isDisabled={this.state.Lvl4List.length < 2}
                            onChange={e => this.onLvlChange(4, e)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                    <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>
        </>)
    }

    onLvlChange(lvl, evt) {
        switch (lvl) {
            case 1:
                this.setState({ institutionLvl1: evt.value, IndustryInstitution: evt.value, changesMade: true }, () => (fieldRequired(this.state.IndustryInstitution > 0 || !this.state.ddLvl1HasItem, "errLvl", "* Required"), this.InitLvl2Dropdown()));
                break;
            case 2:
                this.setState({ institutionLvl2: evt.value, IndustryInstitution: evt.value, changesMade: true }, () => this.InitLvl3Dropdown());
                break;
            case 3:
                this.setState({ institutionLvl3: evt.value, IndustryInstitution: evt.value, changesMade: true }, () => this.InitLvl4Dropdown());
                break;
            default:
                this.setState({ IndustryInstitution: evt.value, changesMade: true });
                break;
        }
    }

    async ProcessStep(stepChangeValue) {
        if (stepChangeValue === "next" && this.state.changesMade) {
            await this.SaveIndustryInstitution();
        }
        else if (this.props.onStepChange)
            this.props.onStepChange(stepChangeValue);
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthroughStep();
        return (
            content
        )
    }

    async loadData() {
        const bearer = "Bearer " + getAuthToken();

        try {
            const response = await fetch(APIPath() + "/global/companystructure", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const Structures = await response.json();
                const IndustryInstitution = getWalkthroughDetails().IndustryInstitution;

                let institutionLvl1 = 0;
                let institutionLvl2 = 0;
                let institutionLvl3 = 0;
                let institutionLvl4 = 0;

                if (IndustryInstitution > 0) {
                    let SelectedStructure = Structures.find(c => c.Id === IndustryInstitution);
                    let Levels = [];
                    while (SelectedStructure) {
                        Levels.push(SelectedStructure.Id);
                        SelectedStructure = Structures.find(c => c.Id == SelectedStructure.ParentId);
                    }

                    if (Levels.length > 0)
                        institutionLvl1 = Levels[Levels.length - 1];

                    if (Levels.length > 1)
                        institutionLvl2 = Levels[Levels.length - 2];

                    if (Levels.length > 2)
                        institutionLvl3 = Levels[Levels.length - 3];
                }
                this.setState({ IndustryInstitution, Structures, institutionLvl1, institutionLvl2, institutionLvl3, institutionLvl4 }, () => this.InitLvl1Dropdown(true));
            }
        } catch (e) {
            console.error("Industry Institutions ::", e);
            this.setState({ loading: false });
        }
    }

    InitLvl1Dropdown(IsInit) {
        const ParentId = 0;
        const data = this.state.Structures;
        const Lvl1List = this.AddItemToDropdown(ParentId, data, 1);
        this.setState({ Lvl1List, ddLvl1HasItem: Lvl1List.length > 1 }, () => this.InitLvl2Dropdown(IsInit));
    }

    InitLvl2Dropdown(IsInit) {
        const ParentId = this.state.institutionLvl1;
        const data = this.state.Structures;
        const Lvl2List = this.AddItemToDropdown(ParentId, data, 2);
        let institutionLvl2 = this.state.institutionLvl2;
        if (!IsInit)
            institutionLvl2 = 0;
        this.setState({ Lvl2List, institutionLvl2, ddLvl2HasItem: Lvl2List.length > 1 }, () => this.InitLvl3Dropdown(IsInit));
    }

    InitLvl3Dropdown(IsInit) {
        const ParentId = this.state.institutionLvl2;
        const data = this.state.Structures;
        const Lvl3List = this.AddItemToDropdown(ParentId, data, 3);
        let institutionLvl3 = this.state.institutionLvl3;
        if (!IsInit)
            institutionLvl3 = 0;
        this.setState({ Lvl3List, institutionLvl3, ddLvl3HasItem: Lvl3List.length > 1 }, () => this.InitLvl4Dropdown(IsInit));
    }

    InitLvl4Dropdown(IsInit) {
        const ParentId = this.state.institutionLvl3;
        const data = this.state.Structures;
        const Lvl4List = this.AddItemToDropdown(ParentId, data, 4);

        let institutionLvl4 = this.state.institutionLvl4;
        if (!IsInit)
            institutionLvl4 = 0;
        this.setState({ Lvl4List, institutionLvl4, loading: false });
    }


    AddItemToDropdown = (ParentId, Data, Level) => {
        const result = [];
        const FilteredData = Data.filter(c => c.ParentId == ParentId);
        switch (Level) {
            case 1:
                if (FilteredData.length > 0) {
                    result.push({ value: 0, label: "- Select Institution -" });
                    FilteredData.map(item => result.push({ value: item.Id, label: item.Name }));
                } else
                    result.push({ value: 0, label: "Not Available" });
                break;
            default:
                if (ParentId > 0) {
                    if (FilteredData.length > 0) {
                        result.push({ value: 0, label: "- Select Institution -" });
                        FilteredData.map(item => result.push({ value: item.Id, label: item.Name }));
                    }
                    else
                        result.push({ value: 0, label: "Not Available" });
                } else {
                    let label = "";
                    if (this.state.institutionLvl1 == 0 && this.state.ddLvl1HasItem) {
                        label = "Please select Level 1";
                    } else if (this.state.institutionLvl2 == 0 && this.state.ddLvl2HasItem)
                        label = "Please select Level 2";
                    else if (this.state.institutionLvl3 == 0 && this.state.ddLvl3HasItem)
                        label = "Please select Level 3";
                    else
                        label = "Not Available";

                    result.push({ value: 0, label });
                    break;
                }
        }
        return result;
    }


    SaveIndustryInstitution() {
        const isValid = fieldRequired(this.state.IndustryInstitution > 0 || !this.state.ddLvl1HasItem, "errLvl", "* Required");
        if (isValid && this.state.ddLvl1HasItem)
            this.saveData();
        else if (!this.state.ddLvl1HasItem)
            this.props.onStepChange("next")
    }

    async saveData() {
        try {
            const bearer = "Bearer " + getAuthToken();
            const Id = getUserId();
            ThrobbleHelper.toggleThrobble(true, "Saving industry institution");
            const data = { Id, UpdateType: 5, UpdateValue: this.state.IndustryInstitution.toString() };
            const response = await fetch(APIPath() + "/global/walkthrough/UpdateUserProfile", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });


            if (response.ok) {
                const result = await response.json();
                if (result) {
                    let WalkthroughDetails = getWalkthroughDetails();
                    WalkthroughDetails.IndustryInstitution = parseInt(result);
                    setWalkthroughDetails(WalkthroughDetails);
                }

                setTimeout(() => {
                    this.props.onStepChange("next", 5);
                }, 1500);
            }
        } catch (e) {

        }
    }
}
