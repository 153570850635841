import React, { Component } from 'react'
import { loader } from '../../helpers/loader';
import { getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { APIProvider, AdvancedMarker, Map, Marker } from '@vis.gl/react-google-maps';
import GoogleMapHelper from '../../helpers/GoogleMapHelper';
import { GetAddressString } from '../../helpers/global';
import { getAuthToken } from '../../helpers/authentication';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { APIPath } from '../../helpers/APIHelper';

export default class StepSix extends Component {

    constructor(props) {
        super(props);
        this.state = {
            position: { lat: -33.8688, lng: 151.2195 },
            address: "",
            GeoLocationGeometry: null,
            GeoLocationAddress: null,
            changesMade: false,
            loading: true
        }

        this.onLocationChange = this.onLocationChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    renderWalkthroughStep() {
        return (<>
            <Modal className='walkthrough-container' isOpen={true} fade fullscreen backdrop="static">
                <ModalHeader className='text-center d-inline'>
                    {this.props.Header}
                </ModalHeader>
                <ModalBody className='text-justify pb-4'>
                    <h3 className='fs-2 fw-bolder'>PLACE OF WORK</h3>
                    <p>Using the Google API provided, please add your main office work address or place of work if you are based on a construction or
                        client site away from your employer's main office. This information is kept private and only used to map the regional, cross-country
                        and cross-continental collaborations and social learning shares. Please do not add a home address here - even if you work from home.
                        In this instance use your closest Post Office or Local Council offices as a geo-location address.</p>

                    <GoogleMapHelper address={this.state.address} position={this.state.position} width={"100%"} height={"calc(100vh - 450px)"} onLocationSelected={this.onLocationChange} />
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                    <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>
        </>)
    }

    async ProcessStep(stepChangeValue) {
        if (stepChangeValue === "next" && this.state.changesMade) {
            await this.SavePlaceOfWork();
        }
        else if (this.props.onStepChange)
            this.props.onStepChange(stepChangeValue);
    }

    onLocationChange(GeoLocationAddress, GeoLocationGeometry) {
        this.setState({ GeoLocationAddress, GeoLocationGeometry, changesMade: true });
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthroughStep();
        return (
            <>
                {content}
            </>
        )
    }

    async loadData() {
        const WalkthroughDetails = getWalkthroughDetails();
        if (WalkthroughDetails.GeoLocationAddress && WalkthroughDetails.GeoLocationGeometry) {
            let GeoLocationGeometry = JSON.parse(WalkthroughDetails.GeoLocationGeometry);
            let GeoLocationAddress = JSON.parse(WalkthroughDetails.GeoLocationAddress);
            const lat = GeoLocationGeometry.location.lat;
            const lng = GeoLocationGeometry.location.lng;
            const position = { lat, lng };
            const address = GetAddressString(GeoLocationAddress);
            this.setState({ GeoLocationGeometry, GeoLocationAddress, position, address, loading: false });
        } else
            this.setState({ loading: false });
    }

    async SavePlaceOfWork() {
        const bearer = "Bearer " + getAuthToken();
        ThrobbleHelper.toggleThrobble(true, "Saving place of work");
        try {
            let WalkthroughDetails = getWalkthroughDetails();
            const GeoLocationAddress = JSON.stringify(this.state.GeoLocationAddress);
            const GeoLocationGeometry = JSON.stringify(this.state.GeoLocationGeometry)
            const UpdateValue = JSON.stringify({ GeoLocationAddress, GeoLocationGeometry });
            const data = { Id: WalkthroughDetails.UserId, UpdateType: 6, UpdateValue };
            const response = await fetch(APIPath() + "/global/walkthrough/UpdateUserProfile", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const result = await response.json();
                if (result) {
                    const address = JSON.parse(result);
                    WalkthroughDetails.GeoLocationAddress = address.GeoLocationAddress;
                    WalkthroughDetails.GeoLocationGeometry = address.GeoLocationGeometry;
                    setWalkthroughDetails(WalkthroughDetails);
                }
                this.props.onStepChange("next", 6);
            }
        } catch (e) {

        }
    }
}
