import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getBase64FromFile } from '../../helpers/base64Helper';
import PhotoCropper from '../Attachments/photo-cropper';
import { GetAvatar } from '../../helpers/global';
import { loader } from '../../helpers/loader';
import { CanSavePoints, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import {
    isMobileSafari,
    isIOS,
    isIOS13,
    isIPhone13,
} from "react-device-detect";
import { getAuthToken } from '../../helpers/authentication';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { RenderSwirl } from '../../helpers/SwirlHelper';
import { APIPath } from '../../helpers/APIHelper';

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            avatar: "",
            imageData: "",
            filename: "",
            stepChangeValue: "",
            showNewAvatar: false,
            loading: true,
            isAppleDevice: false
        }
        this.avatarFile = React.createRef("");
        this.openFileBrowser = this.openFileBrowser.bind(this);
        this.toggleEditImage = this.toggleEditImage.bind(this);
        this.PhotoCropped = this.PhotoCropped.bind(this);
        this.PhotoSelected = this.PhotoSelected.bind(this);
        this.ProcessStep = this.ProcessStep.bind(this);
    }

    componentDidMount() {
        const WalkthroughDetails = getWalkthroughDetails();
        if (WalkthroughDetails)
            this.setState({ username: WalkthroughDetails.Username, avatar: WalkthroughDetails.Avatar, loading: false });
        else
            this.setState({ loading: false });
    }

    checkIsAppleDevice() {
        if (isMobileSafari || isIOS || isIOS13 || isIPhone13) {
            this.setState({
                isAppleDevice: true,
            });
        }
    }

    async ProcessStep(stepChangeValue) {
        if (stepChangeValue === "next" && this.state.imageData) {
            await this.saveProfilePhoto();
        }
        else if (this.props.onStepChange)
            this.props.onStepChange(stepChangeValue);
    }

    toggleEditImage() {
        if (this.state.showNewAvatar) {
            PhotoCropper.toggleShowCropper(true);
        } else this.setState({ editImage: !this.state.editImage });
    }

    openFileBrowser() {
        this.avatarFile.current.click();
    }

    PhotoSelected() {
        let file = this.avatarFile.current.files[0];
        getBase64FromFile(file, (imageData) => {
            this.setState({ showNewAvatar: false, imageData, filename: file.name });
            PhotoCropper.toggleShowCropper(true);
        });
    }

    PhotoCropped(imageData) {
        this.setState({ imageData, showNewAvatar: true });
    }

    renderWalkthrough() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody className='text-justify pb-4'>
                        <h3 className='fs-2 fw-bolder'>PROFILE PHOTO</h3>
                        <p>Firstly, please upload a nice Head and Shoulders photo, or a favourite Avatar if you are more comfortable with that.</p>
                        <div className="col-12 text-center mb-1">
                            <img
                                src={
                                    this.state.showNewAvatar
                                        ? GetAvatar(this.state.imageData)
                                        : GetAvatar(this.state.avatar)
                                }
                                className="img-fluid"
                                alt={this.state.username}
                                style={{ maxHeight: "calc(100vh - 340px)"}}
                            />
                        </div>
                        <div className="text-center col-12">
                            <button
                                type="button"
                                className="btn-icon text d-inline"
                                style={{ border: "1px solid #f1592a" }}
                                onClick={this.openFileBrowser}
                            >
                                {this.state.isAppleDevice ? "Take/Load Image" : "Load Image"} <i className="fas fa-image ms-2"></i>
                            </button>
                            <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                ref={this.avatarFile}
                                onChange={this.PhotoSelected}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                        <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                    </ModalFooter>
                </Modal>

                <PhotoCropper
                    imgSrc={this.state.imageData}
                    OnPhotoCropped={this.PhotoCropped}
                />
            </>
        )
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthrough();
        return (
            content
        )
    }

    async saveProfilePhoto() {
        const bearer = "Bearer " + getAuthToken();
        ThrobbleHelper.toggleThrobble(true, "Saving profile photo");
        try {
            let WalkthroughDetails = getWalkthroughDetails();
            const data = { Id: WalkthroughDetails.UserId, Filename: this.state.filename, ImageData: this.state.imageData };
            const response = await fetch(APIPath() + "/global/userprofile/updateavatar", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const result = await response.json();

                if (result) {
                    WalkthroughDetails.Avatar = result.Avatar;
                    setWalkthroughDetails(WalkthroughDetails);
                }
                this.props.onStepChange("next", 2);
            }
        } catch (e) {

        }
    }
}
