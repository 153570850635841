import React, { Component } from 'react';
import { GetNextLevelDiff, GetUserRanking } from '../../helpers/global';

export class UserLevels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            score: 0,
            userRank: 0,
            hidePoints: false,
            loading: true
        }
    }
    componentDidMount() {
        let userRank = GetUserRanking(this.props.Score);
        this.setState({ userRank, score: this.props.Score, hidePoints: this.props.hidePoints, loading: false });
    }

    RenderRankingTable(UserRank, Score, hidePoints) {
        return (
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-12 w-100'>
                        <table className='mx-auto w-100 my-2'>
                            <tbody>
                                <tr>
                                    {
                                        Array(7).fill().map((_, i) =>
                                            <td key={i} className={this.GetRankingClass(UserRank, i + 1)}></td>
                                        )
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-4 small' hidden={hidePoints}>
                        Points total: <b>{Score}</b>
                    </div>
                    <div className='col-8 small text-end' hidden={hidePoints}>
                        Required for next level: <b>{GetNextLevelDiff(Score)}</b>
                    </div>
                </div>
            </div>
        );
    }

    GetRankingClass(UserRank, Rank) {
        let result = 'ranking ranking-' + Rank;
        if (UserRank >= Rank)
            result += ' active';
        return result
    }

    render() {

        let content = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.RenderRankingTable(this.state.userRank, this.state.score, this.state.hidePoints);
        return (
            <>{content}</>
        );
    }
}
