import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, Collapse } from "reactstrap";
import {
  removeLoginDetails,
  setIsLoggedIn,
} from "../../helpers/authentication";
import {
  getAppVersion,
  getAppVersionFromLocalStorage,
  setAppVersion,
  getSelectGroups,
} from "../../helpers/lookup";
import ThrobbleHelper from "../../helpers/ThrobbleHelper";
import swal from "sweetalert";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      homeActive: "active",
      questionsActive: "",
      contributionsActive: "",
      insightsActive: "",
      postActive: "",
      showLogoutModal: false,
      showCreatePost: false,
      multipleGroupsAvailable: true,
    };

    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
    this.logOut = this.logOut.bind(this);
    this.checkForUpdates = this.checkForUpdates.bind(this);
    this.clearCache = this.clearCache.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
  }

  componentDidMount() {
    this.setState({
      homeActive: window.location.pathname === "/" ? "active" : "",
      questionsActive:
        window.location.pathname === "/questions" ||
        window.location.pathname.includes("/questiondetail")
          ? "active"
          : "",
      contributionsActive:
        window.location.pathname === "/contributions" ||
        window.location.pathname.includes("/contributedetail")
          ? "active"
          : "",
      insightsActive: window.location.pathname === "/insights" ? "active" : "",
      postActive: window.location.pathname.includes("/posequestion")
        ? "active"
        : "",
      multipleGroupsAvailable: getSelectGroups() ? false : true,
    });
  }

  toggleLogoutModal() {
    this.setState({ showLogoutModal: !this.state.showLogoutModal });
  }

  toggleCreatePost() {
    this.setState({
      showCreatePost: !this.state.showCreatePost,
    });
  }

  clearCache() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
      setTimeout(() => {
        window.location = "/";
      }, 500);
    });
  }

  checkForUpdates() {
    const currentAppVersion = getAppVersionFromLocalStorage();
    const latestAppVersion = getAppVersion();

    if (latestAppVersion !== currentAppVersion) {
      swal({
        title: "Updated!",
        text: "Your app has been updated",
        icon: "success",
        button: {
          text: "Ok",
        },
      }).then(() => {
        setAppVersion(latestAppVersion);
        this.clearCache();
      });
    } else {
      swal({
        title: "No Updates",
        text: "There are no updates to apply.",
        icon: "success",
        button: {
          text: "Ok",
        },
      });
    }
  }

  changeGroup() {
    setIsLoggedIn(false);
    window.location.href = "/plain/selectgroup";
  }

  installApp() {
    const userAgent = navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isPC = !isiOS && !isAndroid;

    if(!isiOS){
      localStorage.removeItem("promptDismissed");
      window.location.href = "/";
    }else{
      window.location.href = "/iosinstall";
    }
  }

  render() {
    const { showCreatePost, multipleGroupsAvailable } = this.state;
    return (
      <>
        <div hidden={this.hideFooter}>
          <Collapse
            isOpen={showCreatePost}
            className="position-fixed bottom-0 start-0 w-100 mb-5 bg-white border-top"
            id="collapseExample"
          >
            <div className="p-3">
              <b>What would you like to post?</b>
              <br />
              <div className="row pb-5 pt-3">
                <div className="col-4 text-center">
                  <a href="/posequestion">
                    <img
                      src="../img/global-icons/icon-question.png"
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <br />
                  Question
                </div>
                <div className="col-4 text-center">
                  <a href="/posecontribution">
                    <img
                      src="../img/global-icons/icon-contributions.png"
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <br />
                  Contribution
                </div>
                <div className="col-4 text-center">
                  <a href="/poseinsight">
                    <img
                      src="../img/global-icons/icon-insights.png"
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <br />
                  Insight
                </div>
              </div>
            </div>
          </Collapse>

          {/* <footer className="position-fixed bottom-0 start-0 w-100"> */}
          <footer>
            <table className="w-100 text-center">
              <tbody>
                <tr>
                  <td>
                    <a href="/" className={"nav-home " + this.state.homeActive}>
                      {" "}
                    </a>
                  </td>
                  {/* <td>
                    <a
                      href="/questions"
                      className={"nav-questions " + this.state.questionsActive}
                    >
                      {" "}
                    </a>
                  </td>
                  <td>
                    <a
                      href="/contributions"
                      className={
                        "nav-contributions " + this.state.contributionsActive
                      }
                    >
                      {" "}
                    </a>
                  </td>
                  <td>
                    <a
                      href="/insights"
                      className={"nav-insights " + this.state.insightsActive}
                    >
                      {" "}
                    </a>
                  </td> */}
                  <td>
                    {/* <button
                      className={"nav-createPost " + this.state.postActive}
                      type="button"
                      onClick={() => this.toggleCreatePost()}
                    ></button> */}
                     <a
                      href="/posecollaboration"
                      className={"nav-createPost " + this.state.postActive}
                    >
                      {" "}
                    </a>
                  </td>
                  <td>
                    <button
                      className="nav-menuMain"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#SideMenu"
                      aria-controls="SideMenu"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>Home</td>
                  {/* <td>Questions</td>
                  <td>Contributions</td>
                  <td>Insights</td> */}
                  <td>Create Post</td>
                  <td>Menu</td>
                </tr>
              </tbody>
            </table>
          </footer>

          <div className="offcanvas offcanvas-end" tabIndex="-1" id="SideMenu">
            <div className="offcanvas-body side-menu">
              <table className="w-100 my-4">
                <tbody>
                  <tr>
                    <td>
                      <h1>MENU</h1>
                    </td>
                    <td className="text-end">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul>
                <li>
                  <a className="navMen navHome" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/leaderboard">
                    Leaderboard
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/fullprofile">
                    My Profile
                  </a>
                </li>
                <li>
                  <a className="navMen purpMen" href="/companypurpose">
                    Program
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/collaborationtools">
                    Collaboration Tools
                  </a>
                </li>
                {/* <li>
                  <a className="navMen" href="/trainingtools">
                    Training Tools
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/collaboration">
                    Collaborating &amp; Scoring
                  </a>
                </li> */}
                <li>
                  <a className="navMen" href="/knowledgequest">
                    The Knowledge Quest
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/plain/glossaryLoad">
                    Glossary
                  </a>
                </li>
                <li>
                  <a className="navMen" href="/appFeedback">
                    App Feedback
                  </a>
                </li>
                <li
                  onClick={this.changeGroup}
                  hidden={multipleGroupsAvailable}
                  className="navMen"
                >
                  Change Group
                </li>
                <li>
                  <a className="navMen" href="/updatepassword">
                    Update Password
                  </a>
                </li>
                <li
                  onClick={this.installApp}
                  onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
                >
                  Install App
                </li>
                <li
                  onClick={this.checkForUpdates}
                  onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
                >
                  Check For Updates
                </li>
                <li className="logOut navMen" onClick={this.toggleLogoutModal}>
                  Log-out
                </li>
              </ul>
              <p className="versionNum">Version {getAppVersion()}</p>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.showLogoutModal}
          zIndex={30000}
          className="modal-cyan"
        >
          <ModalHeader className="shadow-sm">Log Out</ModalHeader>
          <ModalBody className="border-0 shadow-lg  text-center">
            <p>Are you sure you want to log out?</p>
            <div className="row ">
              <div className="col-6 mx-auto">
                <button
                  type="button"
                  className="btn-alt mx-auto"
                  onClick={this.toggleLogoutModal}
                >
                  No
                </button>
              </div>
              <div className="col-6 mx-auto">
                <button
                  type="button"
                  className="btn-action mx-auto"
                  onClick={this.logOut}
                >
                  Yes
                </button>
              </div>
            </div>
          </ModalBody>
          <ThrobbleHelper />
        </Modal>
      </>
    );
  }

  logOut() {
    ThrobbleHelper.toggleThrobble(true, "Logging Out...");
    removeLoginDetails();
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  }
}
