import React, { Component } from 'react'
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';

export default class MiniCV extends Component {

    static parentObj = null;
    constructor(props) {
        super(props);
        MiniCV.parentObj = this;
        this.state = {
            id: "",
            jobProfile: "",
            professionalProfile: "",
            experientialProfile: "",
            personalProfile: "",
            loading: true
        }
    }

    componentDidMount() {
        this.setState({ loading: false })
    }

    componentDidUpdate(prevProps, prevState) {
        let { id, jobProfile, professionalProfile, experientialProfile, personalProfile } = this.props;
        if (prevState.jobProfile !== jobProfile || prevState.professionalProfile !== professionalProfile || prevState.experientialProfile !== experientialProfile || prevState.personalProfile !== personalProfile) {
            this.setState({
                id,
                jobProfile,
                professionalProfile,
                experientialProfile,
                personalProfile,
            })
        }
    }

    renderMiniResume(data) {
        if (data.id !== "") {
            return (<div className='cv-container'>
                <h2 className='orange-background'>Mini Résumé</h2>
                <UncontrolledAccordion stayOpen defaultOpen={[]}>
                    <AccordionItem className='mb-1'>
                        <AccordionHeader targetId='job-profile' className='fw-bold '>
                            JOB PROFILE
                        </AccordionHeader>
                        <AccordionBody accordionId='job-profile'>
                            {data.jobProfile}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='mb-1'>
                        <AccordionHeader targetId='professional-profile' className='fw-bold '>
                            PROFESSIONAL PROFILE
                        </AccordionHeader>
                        <AccordionBody accordionId='professional-profile'>
                            {data.professionalProfile}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='mb-1'>
                        <AccordionHeader targetId='experiential-profile' className='fw-bold'>
                            EXPERIENTIAL PROFILE
                        </AccordionHeader>
                        <AccordionBody accordionId='experiential-profile' className=''>
                            {data.experientialProfile}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='mb-1'>
                        <AccordionHeader targetId='personal-profile' className='fw-bold'>
                            PERSONAL PROFILE
                        </AccordionHeader>
                        <AccordionBody accordionId='personal-profile'>
                            {data.personalProfile}
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </div>
            )
        }
    }

    render() {
        let content = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderMiniResume(this.state);
        return (
            content
        )
    }
}
