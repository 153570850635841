/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { ShortenLink, linter, SummariseString } from '../../helpers/global';
import moment from 'moment';


export class ThemeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    formatText(text) {
        var idx = text.indexOf('(@attachment)');
        if (idx >= 0) {
            text = text.substring(0, idx - 1);
        }
        var tagPattern = /<a\s+[^>]*>.*?<\/a>/gi;
        var hasLink = tagPattern.test(text);
        if (hasLink) {
            text = ShortenLink(text);
        } else
            text = linter(text);

        return (
            <div dangerouslySetInnerHTML={{ __html: text }} />
        );
    }

    
    extractUppercaseLetters(text) {
        const uppercaseLetters = text.match(/[A-Z]/g);
        return uppercaseLetters ? uppercaseLetters.join('') : '';
        //todo: if uppercaseLetters is empty then copy the first two characters
    }

    extractThemeNamePill(text){
        let result = this.extractUppercaseLetters(text);
        if (result.length > 0)
            return result.substring(0,2);
        else
            return text.substring(0,1);
    }

    renderThemeIcon(themeName, icon) {
        if (icon !== ""){
            return <img src={"/img/theme-icons/"+icon} alt={themeName} />
        }
        else {
            return <span className='chip'>{this.extractThemeNamePill(themeName)}</span>
        }
    }

    summariseText(text, textLimit){
        //const textLimit = 200;
        var cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");   
        if (cleanText.length > textLimit)
          cleanText = cleanText.substring(0, textLimit)+"...";
        return cleanText;
    }

    renderTextSummary(InitMessage, InitUser, ReplyMessage, ReplyUser) {
        if (ReplyUser === "") {
            return InitUser + " - " + this.summariseText(InitMessage, 80);
        }
        else {
            var content = ReplyUser + " - " + this.summariseText(ReplyMessage, 60);
            content += "<br /><span class='in-response-to'><b>In response to</b> " + InitUser + " - " + this.summariseText(InitMessage, 60) + "</span>";
            return (<div dangerouslySetInnerHTML={{ __html: content }} />);

        }
    }

    renderUnreadCount(Unread) {

        if (Unread > 0 && Unread < 100)
            return (<span className="badge rounded-pill bg-pillcounter">{Unread}</span>);
        else if (Unread >= 100)
            return (<span className="badge rounded-pill bg-pillcounter">99+</span>);
        else
            return "";
    }

    renderUrgentCount(Urgent) {

        if (Urgent > 0 && Urgent < 100)
            return (<><span className="badge rounded-pill bg-pillurgent">{Urgent} Urgent</span><br /></>);
        else if (Urgent >= 100)
            return (<><span className="badge rounded-pill bg-pillurgent">99+ Urgent</span><br /></>);
        else
            return "";
    }

    renderDate(Created, Replied){
        let dt = moment();
        if (Replied !== null)
            return moment(Replied).add(dt.parseZone().utcOffset(), 'minutes').fromNow()
        else if (Created !== null)
            return moment(Created).add(dt.parseZone().utcOffset(), 'minutes').fromNow()
        else return "";
    }


    render() {

        return (
            <>
                <a className='initiations question' href={"collaborations/"+this.props.data.ThemeName}>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className='chip-container'>
                                    {this.renderThemeIcon(this.props.data.ThemeName, this.props.data.ThemeIcon)}
                                </td>
                                <td>
                                    <b>{this.props.data.ThemeName}</b>
                                    <br /><small>
                                        {this.renderTextSummary(this.props.data.InitMessage, this.props.data.InitUserName, this.props.data.ReplyMessage, this.props.data.ReplyUserName)}
                                    </small>
                                </td>
                                <td className="date-container">
                                    {this.renderDate(this.props.data.InitDateCreated, this.props.data.ReplyDateCreated)}<br />
                                    {this.renderUrgentCount(this.props.data.UrgentRequests)}
                                    {this.renderUnreadCount(this.props.data.TotalUnread)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </a>
            </>
        )
    }
}