/* eslint-disable default-case */
import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ProfileEditor from './Controls/ProfileEditor';
import { getAuthToken } from '../../helpers/authentication';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { getUserId, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import { APIPath } from '../../helpers/APIHelper';

export default class StepSevenProfessional extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ProfessionalProfile: "",
            loading: true,
            changesMade: false
        }
    }

    componentDidMount() {
        const ProfessionalProfile = getWalkthroughDetails().ProfessionalProfile;
        this.setState({ ProfessionalProfile, loading: false });
    }

    renderWalkthroughStep() {
        return (
            <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                <ModalBody className='text-justify pb-4'>
                    <h3 className='fs-2 fw-bolder'>MINI-RESUME</h3>
                    <p>Please take a minute to tell your new tribe a little about you - this should not be anything confidential or sensitive or very personal - just some interesting stuff you would like to share about yourself so that kindred spirits can connect and generally enhance the social learning and career mentoring benefits for you.</p>
                    <ProfileEditor
                        ProfileHeader={"PROFESSIONAL PROFILE"}
                        ProfileSubtext={"Typically, your main Academic Qualifications / Professional Registrations / Chartered Status / Business Memberships etc"}
                        value={this.state.ProfessionalProfile}
                        onChange={e => this.setState({ ProfessionalProfile: e, changesMade: true })} />
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                    <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>
        )
    }

    async ProcessStep(stepChangeValue) {
        if (this.state.changesMade) {
            this.saveProfile();
        } else if (this.props.onStepChange)
            this.props.onStepChange(stepChangeValue);
    }
    render() {
        return (
            this.renderWalkthroughStep()
        )
    }

    async saveProfile() {
        const bearer = "Bearer " + getAuthToken();
        ThrobbleHelper.toggleThrobble(true, "Saving Professional Profile");
        try {
            const UserId = getUserId();
            const data = { UserId, ProfileType: "professional", ProfileValue: this.state.ProfessionalProfile };
            const response = await fetch(APIPath() + "/global/walkthrough/UpdateCVProfile", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });


            if (response.ok) {
                const result = await response.json();
                if (result) {
                    let WalkthroughDetails = getWalkthroughDetails();
                    WalkthroughDetails.ProfessionalProfile = result.ProfessionalProfile;
                    setWalkthroughDetails(WalkthroughDetails);
                }

                setTimeout(() => {
                    this.props.onStepChange("next", "7prof");
                }, 1500);
            }
        } catch (e) {

        }
    }
}
