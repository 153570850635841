import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { getTheme } from "../../helpers/lookup";

class UploadSuccess extends Component {
  render() {
    const { state } = this.props.location;
    const { collaborateType } = state;
    return (
      <div data-role="dialog" id="popupPostPosed" data-close-btn="none">
        <div data-role="header" id="successHeader" data-tap-toggle="false">
          <h1>Thank you!</h1>
        </div>
        <div role="main" className="ui-content">
          <p>
            Your post was successfully saved
          </p>
          <div className="successNavContain">
            <div className="successNav successNav-a">
              <a href="/" className="btn-small-action" data-transition="flow">
                Home <i className="fas fa-chevrons-right ms-2"></i>
              </a>
            </div>
            <div className="successNav successNav-b">
              <a
                id="myPostNav"
                href={
                  "/collaborations/" + getTheme()
                }
                className="btn-small-action"
                data-transition="flow"
              > My Posts<i className="fas fa-chevrons-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedUploadSuccess = (props) => {
  const location = useLocation();

  return <UploadSuccess location={location} {...props} />;
};

export default WrappedUploadSuccess;
