import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getWalkthroughPoints } from '../../helpers/lookup'

export default class StepEight extends Component {
    render() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody>
                        <p>OK and well done - that's your App Profile nicely configured and you're already on {getWalkthroughPoints()} points
                            - let's get that to 600 points with just 3 more Social Learning awareness and App functionality
                            training screens to go.</p>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button color='secondary' outline onClick={() => this.props.onStepChange("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                        <Button color='success' onClick={() => this.props.onStepChange("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
